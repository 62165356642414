import React from "react";

import Typography from "../components/Typography";

// Icons.
import BookIcon from "../components/icons/BookIcon";
import HeartIcon from "../components/icons/HeartIcon";
import HouseIcon from "../components/icons/HouseIcon";
import LightBulbIcon from "../components/icons/LightBulbIcon";
import MedalIcon from "../components/icons/MedalIcon";

// Styling.
import { blogMd, blogLg } from "../styles/breakpoints";
import { css } from "@emotion/core";

const iconBlockStyles = {
  wrap: css`
    margin: 0 10px 60px;
    padding: 0 24px;

    @media (${blogMd}) {
      width: calc(100% / 3 - 20px);
    }
  `,
  icon: css`
    width: 24px;
    height: 24px;
    margin-bottom: 18px;
  `,
};

const IconBlock = ({ Icon, title, copy }) => (
  <li css={iconBlockStyles.wrap}>
    {Icon && <Icon css={iconBlockStyles.icon} />}
    <Typography
      as="h3"
      weight="bold"
      variant="Inter-1"
      responsive={[{ size: blogLg, variant: "Inter1" }]}
    >
      {title}
    </Typography>
    <Typography
      variant="Inter-2"
      responsive={[{ size: blogLg, variant: "Inter-1" }]}
    >
      {copy}
    </Typography>
  </li>
);

const iconListStyles = {
  wrap: css`
    padding-top: 80px;
    margin: 0 auto;
    max-width: 1280px;
  `,
  title: css`
    text-align: center;
    margin: 0 auto;
    margin-bottom: 70px;

    @media (${blogMd}) {
      width: calc(100% * (2 / 3));
    }
  `,
  list: css`
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (${blogMd}) {
      padding: 0 10px;
      flex-direction: row;
      flex-wrap: wrap;
    }
  `,
};

const IconList = () => (
  <div css={iconListStyles.wrap}>
    <Typography
      variant="Inter2"
      responsive={[{ size: blogLg, variant: "Inter4" }]}
      css={iconListStyles.title}
    >
      Why educational organizations choose TED Masterclass
    </Typography>
    <ul css={iconListStyles.list}>
      <IconBlock
        Icon={BookIcon}
        title="Reimagine professional development"
        copy="Motivate educators, virtually or in-person, by providing them with engaging professional development."
      />
      <IconBlock
        Icon={HouseIcon}
        title="Celebrate ideas"
        copy="Surface and celebrate the ideas of their educators to stimulate connection and community. "
      />
      <IconBlock
        Icon={LightBulbIcon}
        title="Build essential skills"
        copy="Build educators’ skills to better facilitate idea development with their students."
      />
      <IconBlock
        Icon={MedalIcon}
        title="Amplify educator voices"
        copy="Raise the profile of the school/district/university/educational organization in their communities, with constituents, alumni, funders, etc."
      />
      <IconBlock
        Icon={HeartIcon}
        title="Facilitate collaboration"
        copy="Facilitate collaboration between educators on best practices, experiences, lessons, advice and research."
      />
    </ul>
  </div>
);

export default IconList;
