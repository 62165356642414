import React from "react";
import SVGIcon from "./SVGIcon";

const BookIcon = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      d="M13.2 21.5v2.3h-2.4v-2.3H1.5a1.2 1.2 0 01-1.2-1.2V1.7A1.2 1.2 0 011.5.5h7A4.7 4.7 0 0112 2.1 4.7 4.7 0 0115.5.5h7a1.2 1.2 0 011.2 1.2v18.6a1.2 1.2 0 01-1.2 1.2h-9.3zm8.1-2.3V2.8h-5.8a2.3 2.3 0 00-2.3 2.4v14h8.1zm-10.5 0v-14a2.3 2.3 0 00-2.3-2.4H2.7v16.4h8.1z"
    />
  </SVGIcon>
);

BookIcon.defaultProps = {
  color: "currentColor",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
};

export default BookIcon;
