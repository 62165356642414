import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Image from "./Image";
import Typography from "../components/Typography";

// Styling.
import { blogMd, blogLg } from "../styles/breakpoints";
import { gutters } from "../styles/utilities.styles";
import { css } from "@emotion/core";

const styles = {
  wrap: css`
    overflow: hidden;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    ${gutters};
    padding-top: 60px;
    padding-bottom: 120px;

    @media (${blogMd}) {
      padding-top: 80px;
      flex-direction: row;
      justify-content: space-between;
    }
  `,
  col: css`
    overflow: visible;

    @media (${blogMd}) {
      width: calc(50% - 10px);
    }
  `,
  title: css`
    margin-bottom: 24px;

    @media (${blogMd}) {
      margin-top: 60px;
    }
  `,
  text: css`
    color: #606f7b;

    @media (${blogMd}) {
      width: calc(100% * (5 / 6));
    }
  `,
  img: css`
    width: calc(100% + 48px);
    max-width: none;
    margin: 60px -24px 0;

    @media (${blogMd}) {
      margin: 0 auto;
      width: 514px;
    }

    @media (${blogLg}) {
      width: 655px;
    }
  `,
};

export const imgQuery = graphql`
  query {
    imgSrc: file(relativePath: { eq: "educator-collage-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const EducatorIntro = () => {
  const { imgSrc } = useStaticQuery(imgQuery);

  return (
    <div css={styles.wrap}>
      <section css={styles.content}>
        <div css={styles.col}>
          <Typography
            variant="Inter4"
            responsive={[{ size: blogLg, variant: "Inter6" }]}
            css={styles.title}
          >
            How TED Masterclass for Education benefits your&nbsp;community
          </Typography>
          <Typography
            variant="Inter-2"
            responsive={[{ size: blogLg, variant: "Inter-1" }]}
            css={styles.text}
          >
            Communities of educators are overflowing with brilliant, innovative,
            and - in all likelihood - extremely under-circulated ideas. TED
            Masterclass for Education is a professional learning program that
            will help schools, districts, universities and other
            education-focused organizations surface and celebrate their best
            ideas.
          </Typography>
        </div>
        <div css={styles.col}>
          <Image css={styles.img} {...imgSrc} alt="" />
        </div>
      </section>
    </div>
  );
};

export default EducatorIntro;
