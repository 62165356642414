import React, { createRef, useEffect, useState } from "react";
import { animated, fadeIn } from "../styles/animations";
import { useIntersection, useMeasure } from "react-use";
import { useStaticQuery, graphql } from "gatsby";

import Image from "./Image";
import Pager from "./Pager";
import Typography from "../components/Typography";

import { css, keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import { blogMd, blogLg } from "../styles/breakpoints";

const baseAnimation = css`
  ${animated};
  animation-duration: 0.75s;
  transform: scaleY(1) translateZ(0);
`;

export const sectionTitleOut = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0, -1em, 0);
    visibility: visible;
  }

  99% {
    opacity: 0;
    transform: translate3d(0, -2em, 0);
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
`;

export const sectionTitleIn = keyframes`
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, -2em, 0);
    visibility: visible;
  }
`;

const styles = {
  observable: css`
    margin: 60px 0;
  `,
  stickyBox: css`
    position: sticky;
    top: 80px;
    height: 100vh;
    overflow: hidden;
    transform: scaleY(1) translateZ(0);
  `,
  activeBox: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scaleY(1) translate3d(0, 0, 0);

    @media (${blogMd}) {
      justify-content: center;
    }

    @media (max-height: 800px) {
      justify-content: flex-start;
    }
  `,
  activeBoxContent: css`
    position: relative;
    @media (${blogMd}) {
      width: calc(8 / 12 * 100%);
    }
  `,
  sectionTitle: css`
    position: relative;
    top: 20px;
    padding: 0 24px;
    margin: 0 auto;
    text-align: center;
    max-width: 800px;

    @media (${blogMd}) {
      padding: 0;
    }
  `,
  hideShort: css`
    height: auto;
    opacity: 1;
    ${baseAnimation};

    @media (${blogLg}) and (max-height: 870px) {
      height: 0;
      animation-name: ${sectionTitleOut};
      opacity: 0;
    }
  `,
  showShort: css`
    height: 0;
    opacity: 0;
    ${baseAnimation};

    @media (${blogLg}) and (max-height: 870px) {
      opacity: 1;
      height: auto;
      animation-name: ${sectionTitleIn};
    }
  `,
  list: css`
    margin-top: -500px;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1;
  `,
  listWrap: css`
    padding-top: 80px;
  `,
  pager: css`
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);

    @media (${blogMd}) {
      left: auto;
      right: -48px;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }
  `,
  imgWrap: css`
    position: relative;
    padding-bottom: 40px;
  `,
  imgBox: css`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(450 / 800 * 100%);
    background-color: #dae1e7;

    @media (max-width: 320px) {
      padding-top: calc(350 / 800 * 100%);
    }

    @media (max-height: 750px) {
      padding-top: calc(350 / 800 * 100%);
    }
  `,
  img: css`
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${baseAnimation};
    animation-name: ${fadeIn};
    animation-duration: 0.75s;
  `,
  textBox: css`
    text-align: left;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;

    @media (${blogMd}) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (${blogLg}) {
      width: calc(4 / 6 * 100%);
    }

    @media (max-height: 750px) {
      margin-top: -20px;
      max-height: 20vh;
      overflow: auto;
    }
  `,
  text1: css`
    margin-bottom: 8px;
    ${baseAnimation};
    animation-name: ${fadeIn};
    animation-delay: 0.2s;
  `,
  text2: css`
    ${baseAnimation};
    animation-name: ${fadeIn};
    animation-delay: 0.2s;
    color: #606f7b;
  `,
  spacer: css`
    margin-bottom: 25vh;
    height: calc(100vh - 80px);
  `,
};

const S = {
  TextBoxSizer: styled.div`
    ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}

    @media (max-width: 320px) {
      min-height: 245px;
    }
  `,
  Benefit: styled.article`
    padding: 40px 0;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;

    @media (${blogMd}) {
      padding: 50px 0;
    }
  `,
};

const Observable = React.forwardRef(
  ({ id, activeId, onInView, children }, ref) => {
    const intersection = useIntersection(ref, {
      root: null,
      rootMargin: "80px",
      threshold: 0.75,
    });
    const inView = intersection && intersection.intersectionRatio >= 0.75;

    useEffect(() => {
      if (inView && id !== activeId) {
        onInView();
      }
    }, [inView, id, activeId, onInView]);

    return <div ref={ref}>{children}</div>;
  }
);

const Benefit = ({
  className,
  id,
  img,
  title,
  copy,
  Nav,
  minTextH,
  onHeightChange,
}) => {
  const [ref, { height }] = useMeasure();

  useEffect(() => {
    if (typeof onHeightChange === "function") {
      onHeightChange(height);
    }
  }, [id, onHeightChange, height]);

  return (
    <S.Benefit className={className}>
      <div css={styles.imgWrap}>
        <div key={id} css={styles.imgBox}>
          <Image
            {...img}
            css={styles.img}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
        {Nav && <Nav />}
      </div>
      <S.TextBoxSizer minHeight={minTextH}>
        <div ref={ref} css={styles.textBox}>
          <div key={`text1-${id}`} css={styles.text1}>
            <Typography
              variant="Inter-1"
              weight="bold"
              responsive={[{ size: blogMd, variant: "Inter2" }]}
            >
              {title}
            </Typography>
          </div>
          <div key={`text2-${id}`} css={styles.text2}>
            <Typography
              variant="Inter-2"
              responsive={[{ size: blogMd, variant: "Inter1" }]}
            >
              {copy}
            </Typography>
          </div>
        </div>
      </S.TextBoxSizer>
    </S.Benefit>
  );
};

const ActiveCard = ({ title, active, minTextH, Nav }) => (
  <div css={styles.stickyBox}>
    <div css={styles.activeBox}>
      <div css={styles.activeBoxContent}>
        <Typography
          weight="bold"
          variant="Inter-1"
          as="h2"
          responsive={[
            { size: blogMd, variant: "Inter2" },
            { size: blogLg, variant: "Inter4" },
          ]}
          css={[styles.sectionTitle, styles.hideShort]}
        >
          {title}
        </Typography>
        <Benefit key={active.id} {...active} minTextH={minTextH} Nav={Nav} />
      </div>
    </div>
  </div>
);

export const imagesQuery = graphql`
  query {
    masterclassApp: file(relativePath: { eq: "masterclass-app__1584px.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    adminPortal: file(relativePath: { eq: "admin-portal__1584px.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    educatorHub: file(relativePath: { eq: "educator-hub__1584px.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    workshop: file(relativePath: { eq: "workshop__1584px.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    nominate: file(relativePath: { eq: "nominate__1584px.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const BenefitsList = ({ title, benefits }) => {
  const [textH, setTextHeight] = useState(0);

  const images = useStaticQuery(imagesQuery);
  const refs = benefits.map(() => createRef());
  const items = benefits.map(({ img, ...item }) => ({
    ...item,
    img: images[img],
  }));
  const [active, setActive] = useState(items[0]);
  const activeIdx = items.findIndex(({ id }) => id === active.id);

  const scrollToItem = (idx) => {
    const ref = refs[idx];
    if (ref && ref.current) {
      window.scrollTo(0, ref.current.offsetTop - 80);
    }
  };

  const Nav = () => (
    <div css={styles.pager}>
      <Pager list={benefits} activeIdx={activeIdx} onClick={scrollToItem} />
    </div>
  );

  return (
    <div css={styles.listWrap}>
      <Typography
        weight="bold"
        variant="Inter-1"
        as="h2"
        responsive={[
          { size: blogMd, variant: "Inter2" },
          { size: blogLg, variant: "Inter4" },
        ]}
        css={[styles.sectionTitle, styles.showShort]}
      >
        {title}
      </Typography>
      <ActiveCard title={title} active={active} minTextH={textH} Nav={Nav} />
      <div css={styles.list}>
        {items.map((item, idx) => (
          <Observable
            key={item.id}
            ref={refs[idx]}
            id={item.id}
            activeId={active.id}
            onInView={() => setActive(item)}
          >
            <Benefit
              {...item}
              onHeightChange={(h) => {
                if (h && h > textH) {
                  setTextHeight(h);
                }
              }}
              css={styles.spacer}
            />
          </Observable>
        ))}
      </div>
    </div>
  );
};

BenefitsList.defaultProps = {
  title: `Once your school or organization purchases TED Masterclass, you will be
  able to:`,
  benefits: [
    {
      id: 1,
      img: "masterclassApp",
      title: "Invite your group to access the TED Masterclass course",
      copy: `Selected participants will receive an invitation to access the TED
      Masterclass for Organizations mobile and web app and will be guided
      through the process of developing a TED-style talk.`,
    },
    {
      id: 2,
      img: "adminPortal",
      title: "Track participating educators’ progress",
      copy: `Selected participants will receive an invitation to access the TED Masterclass for Organizations mobile and web app and will be guided through the process of developing a TED-style talk.`,
    },
    {
      id: 3,
      img: "educatorHub",
      title: "Access the TED-Ed Educator Hub",
      copy: `Learning leaders and participants are invited to join the global
      educator community and connect with other educators around the globe.
      They'll also be invited to attend online TED events, interest groups,
      workshops, and more`,
    },
    {
      id: 4,
      img: "workshop",
      title: "Attend an Idea Development workshop with the TED team",
      copy: `Participants can join a workshop with members of the TED team and other educators to learn more insights about developing a throughline and to get feedback as they develop their idea(s).`,
    },
    {
      id: 5,
      img: "nominate",
      title:
        "Nominate participating educators’ TED-style Talks to be featured on the TED-Ed Educator Talks channel",
      copy: `As Participants submit Talks at the end of the course, learning leaders can review talks and are invited to submit them to the TED team for review. `,
    },
  ],
};

export default BenefitsList;
