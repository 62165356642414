import React from "react";
import SVGIcon from "./SVGIcon";

const HouseIcon = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      d="M20.8 21.8V10.2L12 3.3l-8.8 7v11.5h17.6zm2.4 1.2a1.3 1.3 0 01-1.2 1.3H2A1.3 1.3 0 01.7 23V9.6a1.2 1.2 0 01.5-1l10-7.8a1.2 1.2 0 011.6 0l10 7.9a1.3 1.3 0 01.4 1V23zM5.9 13h2.5a3.7 3.7 0 007.4 0h2.6a6.2 6.2 0 01-12.6 0z"
    />
  </SVGIcon>
);

HouseIcon.defaultProps = {
  color: "currentColor",
  width: "24",
  height: "25",
  viewBox: "0 0 24 25",
};

export default HouseIcon;
