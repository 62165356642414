import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Image from "./Image";
import Typography from "../components/Typography";

// Styling.
import { blogMd, blogLg } from "../styles/breakpoints";
import { watermelon } from "../styles/colors";
import { gutters } from "../styles/utilities.styles";
import { css } from "@emotion/core";

const styles = {
  wrap: css`
    ${gutters};
    padding-top: 60px;
    padding-bottom: 60px;

    @media (${blogMd}) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  `,
  content: css`
    margin: 0 auto;

    @media (${blogMd}) {
      display: flex;
      align-items: center;
      width: calc(100% * (5 / 6));
    }
  `,
  title: css`
    margin-bottom: 40px;
    text-align: center;
  `,
  img: css`
    margin-bottom: 32px;

    @media (${blogMd}) {
      width: calc(100% * (6 / 10));
      margin-bottom: 0;
    }
  `,
  text: css`
    @media (${blogMd}) {
      padding-left: 20px;
      width: calc(100% * (4 / 10));
    }
  `,
  link: css`
    color: ${watermelon};
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `,
};

export const imgQuery = graphql`
  query {
    imgSrc: file(relativePath: { eq: "student-wall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const StudentIdeas = () => {
  const { imgSrc } = useStaticQuery(imgQuery);
  return (
    <section css={styles.wrap}>
      <Typography
        variant="Inter2"
        responsive={[{ size: blogLg, variant: "Inter4" }]}
        css={styles.title}
      >
        Inspire your students to share their big ideas
      </Typography>
      <div css={styles.content}>
        <Image {...imgSrc} css={styles.img} alt="" />
        <Typography
          css={styles.text}
          variant="Inter-1"
          responsive={[{ size: blogLg, variant: "Inter-1" }]}
        >
          Interested in developing your students&apos; ideas and presentation
          skills? Learn more about our free TED-Ed Student Talk program and how
          to register here. And to hear one educator&apos;s experience with both
          TED Masterclass and the Student Talk program,{" "}
          <a css={styles.link} href="/educator">
            watch this video
          </a>
          .
        </Typography>
      </div>
    </section>
  );
};

export default StudentIdeas;
