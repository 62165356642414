import React from "react";

import BenefitsList from "../components/BenefitsList";
import CoursePreviewBlock from "../components/CoursePreviewBlock";
import CTASimple from "../components/CTASimple";
import EducatorIntro from "../components/EducatorIntro";
import Footer from "../components/Footer";
import Header from "../components/Header";
import IconList from "../components/IconList";
import Layout from "../components/layout";
import NonProfitBlock from "../components/NonProfitBlock";
import Testimonials from "../components/Testimonials";
import StudentIdeas from "../components/StudentIdeas";

// Styling.
import { css } from "@emotion/core";

const styles = {
  wrap: css`
    background-color: #f8fafc;
  `,
  buffer: css`
    margin-bottom: 110px;
  `,
};

const EducatorPage = () => (
  <Layout css={styles.wrap}>
    <Header />
    <EducatorIntro />
    <Testimonials
      title="Hear from others using TED Masterclass:"
      quotes={[
        {
          id: 1,
          quote: `TED Masterclass is a powerful tool that has increased collaboration across our usually siloed community of social studies teachers. With an event in mind and weekly office hours, social studies educators are working together, providing each other other feedback and developing their best ideas.`,
          name: `Carol Coe`,
          location: `Washington State Council for the Social Studies`,
        },
        {
          id: 2,
          quote: `We are thrilled to be partnering with TED, an organization known for using powerful stories to help ideas worth spreading reach curious audiences around the world (...)Far too many amazing stories of educators impacting students’ lives go untold every day. Through TED Masterclass we hope to shift the narrative around the teaching profession. I can’t wait to hear their stories.`,
          name: `Richard Culatta`,
          location: `International Society for Technology Education`,
        },
      ]}
    />
    <IconList />
    <CTASimple
      title="Are you interested in learning more?"
      cta={{
        label: "Connect with our team",
        href: "https://airtable.com/shrFa9UTiqeNy1MnO",
        target: "_blank",
        "data-track-action": "Register Interest Top",
      }}
    />
    <BenefitsList />
    <div css={styles.buffer}>
      <CTASimple
        title="Are you interested in learning more?"
        cta={{
          label: "Connect with our team",
          href: "https://airtable.com/shrFa9UTiqeNy1MnO",
          target: "_blank",
          "data-track-action": "Register Interest Top",
        }}
      />
    </div>
    <CoursePreviewBlock bg={"transparent"} hideCTA />
    <StudentIdeas />
    <NonProfitBlock />
    <Footer />
  </Layout>
);

export default EducatorPage;
