import React from "react";
import SVGIcon from "./SVGIcon";

const HeartIcon = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      d="M3 2.6a8.2 8.2 0 0111-.4A8.2 8.2 0 0127.5 8c0 2-.6 4-2 5.6l-9.7 9.8a2.5 2.5 0 01-3.4 0h-.1l-9.8-9.8a8.1 8.1 0 01.5-11zm1.7 1.8a5.6 5.6 0 00-.1 7.7l.1.2 9.3 9.3 6.7-6.6-4.4-4.5-1.4 1.4a3.8 3.8 0 01-5.3-5.3l2.6-2.7a5.7 5.7 0 00-7.3.3l-.2.2zm10.7 3.5a1.3 1.3 0 011.7 0l5.4 5.3.8-.9a5.6 5.6 0 00-3.7-9.6c-1.5 0-3 .5-4 1.5l-.2.2-4 4a1.2 1.2 0 00-.1 1.6v.1a1.3 1.3 0 001.7.1l.2-.1 2.2-2.2z"
    />
  </SVGIcon>
);

HeartIcon.defaultProps = {
  color: "currentColor",
  width: "28",
  height: "25",
  viewBox: "0 0 28 25",
};

export default HeartIcon;
