import React from "react";
import SVGIcon from "./SVGIcon";

const LightBulbIcon = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      d="M7.5 20.5h1.2v-6.2h2.5v6.2h1.3c.2-1.5 1-2.7 2.2-4.1l1.2-1.2a7.5 7.5 0 10-11.8 0l1.2 1.2c1.2 1.4 2 2.6 2.2 4.1zm0 2.5v1.3h5V23h-5zm-5.3-6.2a10 10 0 1115.6 0c-.8 1-2.8 2.5-2.8 4.3v3.2a2.5 2.5 0 01-2.5 2.5h-5A2.5 2.5 0 015 24.3V21c0-1.8-2-3.4-2.8-4.3z"
    />
  </SVGIcon>
);

LightBulbIcon.defaultProps = {
  color: "currentColor",
  width: "20",
  height: "27",
  viewBox: "0 0 20 27",
};

export default LightBulbIcon;
