import React from "react";

import Btn from "./Btn";
import Typography from "./Typography";

// Styling.
import { blogMd, blogLg } from "../styles/breakpoints";
import { gutters } from "../styles/utilities.styles";
import { css } from "@emotion/core";

const styles = {
  bg: css`
    background-color: #fff;
  `,
  wrap: css`
    ${gutters};
    padding-top: 60px;
    padding-bottom: 40px;
  `,
  content: css`
    text-align: center;
    margin: 0 auto;

    @media (${blogMd}) {
      width: calc(100% * (2 / 3));
    }
  `,
  mb: css`
    margin-bottom: 32px;

    @media (${blogLg}) {
      margin-bottom: 48px;
    }
  `,
  link: css`
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `,
};

const NonProfitBlock = () => (
  <div css={styles.bg}>
    <section css={styles.wrap}>
      <div css={styles.content}>
        <Typography
          variant="Inter-1"
          responsive={[{ size: blogLg, variant: "Inter-1" }]}
          css={styles.mb}
        >
          TED is a nonprofit. All revenue generated through TED Masterclass is
          reinvested into sparking and celebrating great educator and student
          ideas throughout the world. Pricing varies based on your school or
          district’s size and needs.
        </Typography>
        <Btn
          css={styles.mb}
          label={"Connect with our team"}
          target={"_blank"}
          href="https://airtable.com/shrFa9UTiqeNy1MnO"
        />
        <Typography
          variant="Inter-1"
          responsive={[{ size: blogLg, variant: "Inter-1" }]}
        >
          Have questions?{" "}
          <a
            href="https://masterclass-help.ted.com/hc/en-us"
            css={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            data-track-action="Check out FAQs"
          >
            Check out our FAQs
          </a>
          .
        </Typography>
      </div>
    </section>
  </div>
);

export default NonProfitBlock;
