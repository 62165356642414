import React from "react";

import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { blogMd } from "../styles/breakpoints";

const S = {
  List: styled.ul`
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (${blogMd}) {
      flex-direction: column;
    }
  `,
  Button: styled.button`
    opacity: 0.3;
    padding: 3px 0;
    margin: 0 6px;
    cursor: pointer;

    @media (${blogMd}) {
      padding: 0 3px;
    }

    ${({ active }) =>
      active &&
      css`
        opacity: 1;
      `};

    &::after {
      content: "";
      display: block;
      width: 40px;
      height: 3px;
      background: #51677b;

      @media (${blogMd}) {
        width: 3px;
        height: 40px;
        margin: 6px 0;
      }
    }
  `,
};

const Pager = ({ list, activeIdx, onClick }) => {
  if (!Array.isArray(list) || list.length < 1) {
    return <></>;
  }

  return (
    <S.List>
      {list.map((item, idx) => (
        <li key={idx}>
          <S.Button
            active={activeIdx === idx}
            onClick={() => onClick(idx)}
          ></S.Button>
        </li>
      ))}
    </S.List>
  );
};

export default Pager;
