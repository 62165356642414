import React from "react";
import SVGIcon from "./SVGIcon";

const MedalIcon = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      d="M16.3 18v8.6a.6.6 0 01-1 .6L10 24l-5.3 3.2a.6.6 0 01-1-.6v-8.5a10 10 0 1112.6 0zm-10 1.5v3.8l3.7-2.2 3.8 2.2v-3.8a10 10 0 01-7.6 0zm3.7-1.7a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
    />
  </SVGIcon>
);

MedalIcon.defaultProps = {
  color: "currentColor",
  width: "20",
  height: "28",
  viewBox: "0 0 20 28",
};

export default MedalIcon;
